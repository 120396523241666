import React from "react";
import compose from "recompose/compose";
import branch from "recompose/branch";
import { Redirect } from "react-router";
import { graphql } from "react-apollo";
import { withRouter } from "react-router";
import DefaultCheckAuthQuery from "./CheckAuthQuery.gql";
import Loadable from "theme/components/helpers/Loadable";

const checkAuth = ({
  CheckAuthQuery = DefaultCheckAuthQuery,
  redirectLocation = "/",
  condition = (me) => me && me.id !== null,
}) => {
  return compose(
    graphql(CheckAuthQuery, {
      options: {
        fetchPolicy: "network-only",
      },
      props: ({ data }) => ({
        loading: data.loading,
        me: !data.loading && data.me,
        error: data.error,
      }),
    }),
    Loadable((props) => !props.loading, true),
    withRouter,
    branch(
      (props) => condition(props.me),
      (BaseComponent) => BaseComponent,
      () => (props) =>
        (
          <Redirect
            to={
              typeof redirectLocation === "function"
                ? redirectLocation(props)
                : redirectLocation
            }
          />
        )
    )
  );
};

export default checkAuth;
