import React from "react";
import { compose } from "recompose";
import { injectIntl, defineMessages } from "react-intl";

import FormActions from "theme/components/molecules/Form/FormActions";
import FormItem from "theme/components/molecules/Form/Item";
import LabelledIcon from "theme/components/molecules/LabelledIcon";
import { ErrorAlert } from "theme/components/molecules/Alert";
import { Email, Password } from "theme/components/atoms/Form/Input";
import SubmitButton from "theme/components/atoms/Button/SubmitButton";
import Link from "theme/components/atoms/Typography/Link";
import Form from "theme/components/atoms/Form/Form";
import { H5 } from "theme/components/atoms/Typography/Heading";
import EnhanceLoginForm from "./EnhanceLoginForm";
import LoginMutation from "./LoginMutation.gql";
const messages = defineMessages({
  error: {
    id: "modules.User.LoginForm.loginErrorBadWebsite",
    defaultMessage: "You are on public website. Please login into your",
  },
  errorCta: {
    id: "modules.User.LoginForm.loginErrorBadWebsiteCta",
    defaultMessage: "retail area",
  },
  title: {
    id: "modules.User.LoginForm.title",
    defaultMessage: "Sign in",
  },
  submit: {
    id: "modules.User.LoginForm.submit",
    defaultMessage: "Sign in",
  },
  emailLabel: {
    id: "modules.User.LoginForm.email.label",
    defaultMessage: "Email",
  },
  emailErrorRequired: {
    id: "modules.User.LoginForm.email.errorRequired",
    defaultMessage: "Please enter an email",
  },
  emailErrorMalformed: {
    id: "modules.User.LoginForm.email.errorMalformed",
    defaultMessage: "Please enter a valid email address",
  },
  passwordLabel: {
    id: "modules.User.LoginForm.password.label",
    defaultMessage: "Password",
  },
  passwordErrorRequired: {
    id: "modules.User.LoginForm.password.errorRequired",
    defaultMessage: "Please enter a password",
  },
  forgotPassword: {
    id: "modules.User.LoginForm.forgotPassword",
    defaultMessage: "Forgot your password?",
  },
});

const LoginForm = (props) => {
  const { formatMessage } = props.intl;
  return (
    <div className="account-form--register__connection">
      <div className="account-form--register__connection__title account-form--register__connection__title--login">
        <H5 as="h2">{formatMessage(messages.title)}</H5>
      </div>
      <div className="account-form--register__connection__form">
        <Form onValidSubmit={(data) => props.authenticateUser(data)}>
          {props.errorMessage && props.errorMessage === "bad_website" && (
            <ErrorAlert>
              <div
                dangerouslySetInnerHTML={{
                  __html: `${formatMessage(
                    messages.error
                  )} <a href="https://b2b-fr.collegien-shop.com/">${formatMessage(
                    messages.errorCta
                  )}</a>`,
                }}
              />
            </ErrorAlert>
          )}
          {props.errorMessage && props.errorMessage !== "bad_website" && (
            <ErrorAlert>{props.errorMessage}</ErrorAlert>
          )}
          <FormItem label={formatMessage(messages.emailLabel)}>
            <Email
              id="email"
              name="email"
              required
              validationErrors={{
                required: formatMessage(messages.emailErrorRequired),
                isEmail: formatMessage(messages.emailErrorMalformed),
              }}
            />
          </FormItem>
          <FormItem label={formatMessage(messages.passwordLabel)}>
            <Password
              id="password"
              name="password"
              required
              validationError={formatMessage(messages.passwordErrorRequired)}
            />
          </FormItem>
          <Link to="/account/password-request">
            <LabelledIcon icon="chevron-right" size="tiny">
              {formatMessage(messages.forgotPassword)}
            </LabelledIcon>
          </Link>
          <FormActions appearance="full">
            <SubmitButton
              state={props.commandPending ? "pending" : undefined}
              appearance="primary"
            >
              {formatMessage(messages.submit)}
            </SubmitButton>
          </FormActions>
        </Form>
      </div>
    </div>
  );
};

export default compose(
  EnhanceLoginForm({ LoginMutation }),
  injectIntl
)(LoginForm);
