import React from "react";
import { compose, withState, withProps } from "recompose";
import { injectIntl, FormattedMessage } from "react-intl";
import makeCommandDispatcher from "web/core/apollo/makeCommandDispatcher";
import { trackLoginSubmit } from "../../../components/helpers/AnalyticsV4/TrackAnlyticsV4";
import { withShop } from "web/core/shop/ShopContext";

const EnhanceLoginForm = ({ LoginMutation }) =>
  compose(
    injectIntl,
    withState("canSubmit", "setCanSubmit", false),
    withState("errorMessage", "setErrorMessage", null),
    withShop(),
    withProps((props) => ({
      onSuccess: () => {
        props.onLoginSuccess();
      },
    })),
    makeCommandDispatcher({
      authenticateUser:
        (props) =>
        ({ email, password }) => {
          props.setErrorMessage(null);
          return {
            options: {
              mutation: LoginMutation,
              refetchQueries: ["User", "Cart", "CartQty"],
              variables: {
                username: email,
                password: password,
              },
            },
            callback: ({ data, status }) => {
              if (status !== "error") {
                trackLoginSubmit(props.shop);
                return;
              }
              if (
                data &&
                data.errorMessage &&
                data.errorMessage === "bad_website"
              ) {
                props.setErrorMessage("bad_website");
              } else {
                props.setErrorMessage(
                  <FormattedMessage
                    id="modules.User.LoginForm.loginError"
                    defaultMessage="The email address or password is incorrect."
                  />
                );
              }
            },
          };
        },
    })
  );

export default EnhanceLoginForm;
