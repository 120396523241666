import { compose } from "recompose";
import { graphql } from "react-apollo";

import withTrackPage from "theme/modules/Analytics/withTrackPage";
import withLoginRedirection from "./withLoginRedirection";
import checkAuth from "theme/modules/Router/checkAuth";
import { trackGlobalPage } from "../../components/helpers/AnalyticsV4/TrackAnlyticsV4";

const EnhanceLogin = ({ LoginConfigurationQuery }) =>
  compose(
    checkAuth({
      condition: (me) => me && me.id === null,
    }),
    withLoginRedirection(typeof window !== "undefined" ? window : {}),
    withTrackPage("Login"),
    trackGlobalPage(),
    graphql(LoginConfigurationQuery, {
      props: ({ data }) => {
        return {
          loading: data.loading,
          loginSubscriptionDescription:
            data.storeConfiguration &&
            data.storeConfiguration.loginSubscriptionDescription,
          backgroundImage:
            data.storeConfiguration &&
            data.storeConfiguration.loginSubscriptionBackgroundMedia &&
            data.storeConfiguration.loginSubscriptionBackgroundMedia.url,
        };
      },
    })
  );

export default EnhanceLogin;
