import React from "react";
import { FormattedMessage } from "react-intl";

import Wysiwyg from "theme/modules/Wysiwyg";
import LoginForm from "theme/modules/User/LoginForm";
import Reassurance from "theme/components/organisms/Reassurance";
import { H5 } from "theme/components/atoms/Typography/Heading";
import { ButtonLink } from "theme/components/atoms/Typography/Link";
import EnhanceLogin from "./EnhanceLogin";
import LoginConfigurationQuery from "./LoginConfigurationQuery.gql";
import ResizedImage from "theme/components/atoms/ResizedImage";

const Login = (props) => {
  return (
    <React.Fragment>
      <div className="account-form--register">
        <div className="account-form--register__wrapper">
          <LoginForm
            onLoginSuccess={() => {
              // Temporary fix, waiting for more cache control on apollo
              window.location.href = props.loginRedirectPath;
              props.clearLoginRedirectPath();
            }}
          />
          <div className="login-register">
            <div className="login-register__title">
              <H5 as="h2">
                <FormattedMessage
                  id="pages.modules.Login.create"
                  defaultMessage="Create an account"
                />
              </H5>
            </div>
            <div className="login-register__description">
              {!props.loading && (
                <Wysiwyg content={props.loginSubscriptionDescription} />
              )}
              <div className="account-form--register__register-link">
                <ButtonLink to="/register" buttonAppearance="primary">
                  <FormattedMessage
                    id="modules.User.AccountLink.signup"
                    defaultMessage="Create an account"
                  />
                </ButtonLink>
              </div>
            </div>
          </div>
        </div>
        {props.backgroundImage && (
          <ResizedImage
            className="account-form--register__image"
            src={props.backgroundImage}
            alt="login background"
            format="backgroundForm"
            cover
          />
        )}
      </div>

      <Reassurance big />
    </React.Fragment>
  );
};

export default EnhanceLogin({ LoginConfigurationQuery })(Login);
